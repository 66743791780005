import { ProductPrice } from '../../../utils/productPrice';
import * as S from './SubExtraInfo.styles';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';

export const SubExtraInfo = () => {
  const { currentCategory, currentProduct, extraObjects } = useContext(
    FunnelContext
  );

  const display_title = ProductPrice({
    type: `display_title`,
    currentCategory: currentCategory,
    currentProduct: currentProduct,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });

  return (
    <S.SubExtraInfoContent>
      <div className={'display-title'}>{display_title}</div>
    </S.SubExtraInfoContent>
  );
};

export default SubExtraInfo;
